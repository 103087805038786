
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'accordion-navigation toggleLink is-open',
        'role': 'button',
        'data-collapsible': '#accordion--fitments',
        'aria-label': 'Fitments',
        'aria-controls': 'accordion--fitments',
        'aria-expanded': 'true',
        'onClick': e => window.Convermax.handleFitmentTabClick(e),
        'key': '0'
    }, _createElement('h5', { 'className': 'accordion-title' }, 'Fitments'), _createElement('div', { 'className': 'accordion-navigation-actions' }, _createElement('svg', {
        'className': 'icon accordion-indicator toggleLink-text toggleLink-text--off',
        'viewBox': '0 0 12 12'
    }, _createElement('g', {
        'id': 'plus-Symbols',
        'stroke': 'none',
        'strokeWidth': '1',
        'fillRule': 'evenodd'
    }, _createElement('g', {
        'id': 'plus-InterfaceIcons/PlusExpand',
        'fillRule': 'nonzero'
    }, _createElement('path', {
        'd': 'M5,5 L5,1 C5,0.44771525 5.44771525,0 6,0 C6.55228475,0 7,0.44771525 7,1 L7,5 L11,5 C11.5522847,5 12,5.44771525 12,6 C12,6.55228475 11.5522847,7 11,7 L7,7 L7,11 C7,11.5522847 6.55228475,12 6,12 C5.44771525,12 5,11.5522847 5,11 L5,7 L1,7 C0.44771525,7 8.8817842e-16,6.55228475 8.8817842e-16,6 C8.8817842e-16,5.44771525 0.44771525,5 1,5 L5,5 Z',
        'id': 'plus-Combined-Shape'
    })))), _createElement('svg', {
        'className': 'icon accordion-indicator toggleLink-text toggleLink-text--on',
        'viewBox': '0 0 12 2'
    }, _createElement('g', {
        'id': 'minus-Symbols',
        'stroke': 'none',
        'strokeWidth': '1',
        'fillRule': 'evenodd'
    }, _createElement('g', {
        'id': 'minus-InterfaceIcons/minusClose',
        'transform': 'translate(0.000000, -5.000000)',
        'fillRule': 'nonzero'
    }, _createElement('path', {
        'd': 'M1,7 C0.44771525,7 0,6.55228475 0,6 C0,5.44771525 0.44771525,5 1,5 L11,5 C11.5522847,5 12,5.44771525 12,6 C12,6.55228475 11.5522847,7 11,7 L1,7 Z',
        'id': 'minus-Line-2'
    })))))) : null;
}
        export const componentNames = []