import customUriParser from './uriParser.js';

const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const ignoreFields = ['Universal', 'Fitment'];

globalThis.Convermax.handleFitmentTabClick = (e) => {
  const tabTitle = e.target.closest('#cm_FitmentTableTab .accordion-navigation');
  const tabContent = window.document.querySelector('#cm_FitmentTable');

  if (tabTitle && tabContent) {
    tabTitle.classList.toggle('is-open');
    tabContent.classList.toggle('is-open');
  }
};

export default {
  platform: 'bigcommerce',
  customUriParser,
  searchPageUrl: '/pages/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: '/shop-by-category', field: 'category' },
      { pathname: '/brands', field: 'brand_name' },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      location: {
        selector: '#quickSearch',
        class: 'cm_mobile-hide',
      },
      template: 'SearchBox',
    },
    {
      name: 'MobileSearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        insertBefore: '.navUser-item--account',
        class: 'cm_desktop-hide',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 700,
      initCollapsed: true,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 450,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-item--account',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: 'body .productView .productView-specs' },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 600,
    },
    {
      name: 'FitmentTable',
      location: {
        insertAfter: 'body.page-product #accordion--description',
        class: 'accordion-content is-open',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { insertAfter: 'body.page-product #accordion--description' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        selector: '#cm-category-list',
        class: 'cm_vehicle-categories__category',
      },
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: true,
      isVehicleWidgetDisabled: true,
      columnBreakpoint: 600,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      isVehicleWidgetDisabled: true,
      view: 'grid',
      columnBreakpoint: 600,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
